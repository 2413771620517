const timelinedata = [
  {
    id: 1,
    title: "Registration",
    date: "21st March 2025",
    time: "8:00AM - 5:00PM",
    location: "Conference Room",
    icon: "casual",
    day: 0,
  },
  {
    id: 2,
    title: "Inauguration",
    date: "21st March 2025",
    time: "5:00PM - 6:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 0,
  },
  {
    id: 3,
    title: "Treasure Hunt",
    date: "21st March 2025",
    time: "6:00PM - 8:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 0,
  },
  {
    id: 4,
    title: "Deep Sea Disputes",
    date: "22nd March 2025",
    time: "8:00AM - 11:00AM",
    location: "Seminar Room",
    icon: "casual",
    day: 1,
  },
  {
    id: 5,
    title: "Boat Wars round 1 and 2",
    date: "22nd March 2025",
    time: "11:00AM - 1:00PM",
    location: "Pool Area",
    icon: "casual",
    day: 1,
  },
  {
    id: 6,
    title: "Guest Lecture (VEDAM)",
    date: "22nd March 2025",
    time: "2:00PM - 3:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 1,
  },
  {
    id: 7,
    title: "Wave Quest",
    date: "22nd March 2025",
    time: "3:00PM - 5:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 1,
  },
  {
    id: 8,
    title: "Quizathon",
    date: "22nd March 2025",
    time: "5:00PM - 7:00PM",
    location: "CL 6",
    icon: "casual",
    day: 1,
  },

  {
    id: 9,
    title: "DJ Night",
    date: "22nd March 2023",
    time: "7:00PM - 11:00PM",
    location: "Vikramshila Foyer",
    icon: "ship",
    day: 1,
  },
  {
    id: 10,
    title: "Boat Wars Round 3",
    date: "23rd March 2025",
    time: "8:00AM - 12:00PM",
    location: "Pool Area",
    icon: "ship",
    day: 2,
  },

  {
    id: 11,
    title: "Guest Lecture (IRS)",
    date: "23rd March 2025",
    time: "1:00PM - 2:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 2,
  },
  {
    id: 12,
    title: "Paper Prep",
    date: "23rd March 2025",
    time: "2:00PM - 5:00PM",
    location: "OENA Department",
    icon: "casual",
    day: 2,
  },
  {
    id: 13,
    title: "closing ceremony",
    date: "23rd March 2025",
    time: "5:00PM - 6:00PM",
    location: "Seminar Room",
    icon: "casual",
    day: 2,
  },
];

export default timelinedata;
